.unit-display {
  width: 38px;
  height: 38px;
  place-content: center;
  text-align: center;
  margin: 0;
  padding: 4px 6px;
  font-family: value($font-family, primary);
  font-size: 20px;
  font-weight: value($font-weight, bold);
  color: value($color-grey, darkest);
  border-radius: 2px;
}
